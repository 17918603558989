import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {AppConfig} from '../../config/app.config';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept' : 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class RestService {
  API_ENDPOINT: string;

  constructor(private http: HttpClient, private config: AppConfig,) { 
    this.API_ENDPOINT = this.config.getConfig('API_ENDPOINT');
  }
  private extractData(res: Response) {
    let body = res;
    return body || { };
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  buildGetRequest(uripart: string)
  {
    return this.http.get(this.API_ENDPOINT + uripart);
  }
  buildDelRequest(uripart: string)
  {
    return this.http.delete(this.API_ENDPOINT + uripart);
  }
  buildPutRequest(uripart: string, body: object)
  {
    return this.http.put(this.API_ENDPOINT + uripart, body);
  }
  buildPostRequest(uripart: string, body: object)
  {
    return this.http.post(this.API_ENDPOINT + uripart, body);
  }
  buildPostRequestFormData(uripart: string, body: FormData)
  {
    return this.http.post<any>(this.API_ENDPOINT + uripart, body, {headers: {'Accept':'application/json'}});
  }
  getProjects(): Observable<any> {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.get(this.API_ENDPOINT + 'projects?token=' + user.token, {
      headers: {'Accept':'application/json'}
    }).pipe(
        map(this.extractData)
      );
  }
}
