import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import {AppConfig} from '../../config/app.config';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Injectable()
export class PDFGeneratorService {
    API_ENDPOINT: string;
    constructor(private rest:RestService, private config: AppConfig) 
    { 
      this.API_ENDPOINT = this.config.getConfig('ENDPOINT');
    }
    format(inputDate) {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        }
      }

    
    public generateReport1(projectid)
    {
        this.rest.buildGetRequest('projects/' + projectid).subscribe((res : any)=>
        {
            let reqArray: string[]=[];
            /*if(res.requiresFERC)
            {
                reqArray.push("FERC");
            }
            if(res.requiresSWRCB)
            {
                reqArray.push("SWRCB");
            }
            if(res.requiresDWR)
            {
                reqArray.push("DWR");
            }
            if(res.requiresDSOD)
            {
                reqArray.push("DSOD");
            }
            if(res.requiresUSFS)
            {
                reqArray.push("USFS");
            }
            if(res.requiresNERC)
            {
                reqArray.push("NERC");
            }*/

            var regulatoryString=reqArray.join(", ");

            this.rest.buildGetRequest('projecttotals/' + projectid).subscribe((tots : any)=>
            {
                var doc = new jsPDF('p', 'mm', 'letter');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                var img = new Image();
                img.src = 'assets/img/logo.png';
                doc.addImage(img, 'PNG', 10, 7, 18, 16);
                doc.setFontSize(14);
                doc.setFontStyle('bold');
                doc.text('MCWD CIP', 206, 20, 'right');
                doc.setLineWidth(1.0); 
                doc.line(10,25,206,25, 'DF');
                
                let thead = 
                    [
                        
                        [   
                            {"content":res.project_title, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_source, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ],
                        [   
                            {"content":res.CIP_0, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_trigger, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ]
                        //[res.title]
                    ];

                doc.autoTable(
                {
                    head: thead,
                    body:
                        [
                            
                            [
                                //res.facility.code, res.facility.name, res.budget_category.name, regulatoryString
                                '','mw','ms','ow','os','recycled','cm','cs','district-wide'
                            ],
                            [
                                '% Budget Split', res.marina_water_allocation, res.marina_sewer_allocation, res.ord_water_allocation, res.ord_sewer_allocation, res.recycled_allocation, res.combined_water_allocation, res.combined_sewer_allocation, res.district_wide_allocation,
                            ]
                        ],
                
                    theme:'grid',
                    startY: 30,
                    //headStyles: { halign:"center", fillColor: "#ffffff"},
                    
                    tableLineWidth: 1,
                    tableLineColor:0
                });
                doc.setFontSize(14);
                let curOffset= doc.autoTable.previous.finalY+15;

                if (res.project_description) {
                    doc.setFontStyle('bold');
                    doc.text("Project Description", 20, curOffset);
                    curOffset+= doc.getLineHeight("Project Description") / doc.internal.scaleFactor
                    
                    doc.setFontSize(12);
                    doc.setFontStyle('normal');
                    var splitTitle = doc.splitTextToSize(res.project_description, 180);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;
                }
                if (res.justification) {
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text("Justification", 20, curOffset);
                    curOffset+= doc.getLineHeight("Justification") / doc.internal.scaleFactor

                    doc.setFontSize(12);
                    doc.setFontStyle('normal');
                    splitTitle = doc.splitTextToSize(res.justification, 180);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;
                }
                if (res.path_cover!='') {
                    doc.setFontStyle('bold');
                    doc.text("Map Location", 20, curOffset);
                    curOffset+= doc.getLineHeight("Map Location") / doc.internal.scaleFactor
                    
                        var img = new Image();
                        img.src = this.API_ENDPOINT + "storage/" + res.path_cover;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                        doc.addImage(img, 'PNG', 37, curOffset, 152, 85);
                    
                    curOffset+=100;
                }
                doc.setFontStyle('bold');
                /*doc.text("Risk Matrix Evaluation", 20, curOffset);
                curOffset+= doc.getLineHeight("Problem Statement") / doc.internal.scaleFactor +8;

                let bodyArray:any[]=[];
                let matrixTot: number=0;
                res.risk_matrix.forEach(element => {
                    var tmp=[
                    element.risk_category.description, 
                    element.score,
                    element.notes
                    ];
                    bodyArray.push(tmp);
                    matrixTot+=element.score;
                });
                let ftmp=[
                    {content: "Total", styles: { fontStyle: "bold"}},
                    {content: matrixTot, styles: { fontStyle: "bold"}},
                    {content: ""}
                ];
                bodyArray.push(ftmp);
                doc.autoTable(
                {
                    head:
                    [
                    ['Service Level', 'Rating', 'Notes']
                    ],
                    
                    body:bodyArray,
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                    {
                        0: {halign: "left"},
                        1: { halign: "center"},
                        2: { halign: "center"}
                    },
                   
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { halign:"center", fillColor:"#0397d5"},
                    
                    });
                    //doc.line(10,doc.autoTable.previous.finalY + 8 ,206,doc.autoTable.previous.finalY + 8 , 'DF');
                    */
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    doc.text(new Date().toLocaleDateString('en-US'), 10, 270, 'left');
                    doc.text('Page 1/4', 205, 270, 'right');
                    doc.addPage('letter', 'p')
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                    var img = new Image();
                    img.src = 'assets/img/logo.png';
                    doc.addImage(img, 'PNG', 10, 7, 18, 16);
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text('MCWD CIP', 206, 20, 'right');
                    doc.setLineWidth(1.0); 
                    doc.line(10,25,206,25, 'DF');
                    curOffset=35;
                    //Map here before
                    doc.text("Risk Matrix Evaluation", 20, curOffset);
                    curOffset+= doc.getLineHeight("Problem Statement") / doc.internal.scaleFactor +8;

                    let bodyArray:any[]=[];
                    let matrixTot: number=0;
                    res.risk_matrix.forEach(element => {
                        var tmp=[
                        element.risk_category.description, 
                        element.score,
                        element.notes
                        ];
                        bodyArray.push(tmp);
                        matrixTot+=element.score;
                    });
                    let ftmp=[
                        {content: "Total", styles: { fontStyle: "bold"}},
                        {content: matrixTot, styles: { fontStyle: "bold"}},
                        {content: ""}
                    ];
                    bodyArray.push(ftmp);
                    doc.autoTable(
                    {
                        head:
                        [
                        ['Service Level', 'Rating', 'Notes']
                        ],
                        
                        body:bodyArray,
                        theme:'grid',
                        startY: curOffset,
                        columnStyles: 
                        {
                            0: {halign: "left"},
                            1: { halign: "center"},
                            2: { halign: "center"}
                        },
                    
                        tableLineWidth: 0.3,
                        tableLineColor:0,
                        headStyles: { halign:"center", fillColor:"#0397d5"},
                        
                    });
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    doc.text(new Date().toLocaleDateString('en-US'), 10, 270, 'left');
                    doc.text('Page 2/4', 205, 270, 'right');
                    doc.addPage('letter','l');
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text('MCWD CIP', 266, 20, 'right');
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                    var img = new Image();
                    img.src = 'assets/img/logo.png';
                    doc.addImage(img, 'PNG', 10, 7, 18, 16);
                    doc.setLineWidth(1.0); 
                    doc.line(10,25,266,25, 'DF');
                    
                    doc.autoTable(
                    {
                    body:
                        [
                        [
                            'Start Year', res.startYear
                        ]
                        ],
                        theme:'grid',
                        startY: 30,
                        columnStyles: 
                        {
                        0: {cellWidth: 20},
                        1: {cellWidth: 20}
                        },
                        tableLineWidth: 0.3,
                        tableLineColor:0,
                        tableWidth:'wrap'
                    });
                    curOffset=doc.autoTable.previous.finalY + 8;

                    let head = 
                    [
                        [{"content":"Hours", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                        //{content: 'Hours', colSpan: 7, styles: {halign: 'center', fillColor: [22, 160, 133]}},
                        [{"content":"Labor Category", colspan:1},{"content":res.startYear, colspan:1}, {"content":parseInt(res.startYear) + 1, colspan:1}, {"content":parseInt(res.startYear) + 2, colspan:1}, {"content":parseInt(res.startYear) + 3, colspan:1}, {"content":parseInt(res.startYear) + 4, colspan:1}, {"content": (parseInt(res.startYear)+5)+ "+", colspan:1}],
                    ];
                    let body=[];
                    let y0tot: number=0;
                    let y1tot: number=0;
                    let y2tot: number=0;
                    let y3tot: number=0;
                    let y4tot: number=0;
                    let y5tot: number=0;
                    tots.hours.forEach(element => {
                        var tmp=[ element.title,  parseInt(element.total0y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total1y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total2y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total3y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total4y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total5y).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                        body.push(tmp);
                        y0tot+=parseInt(element.total0y);
                        y1tot+=parseInt(element.total1y);
                        y2tot+=parseInt(element.total2y);
                        y3tot+=parseInt(element.total3y);
                        y4tot+=parseInt(element.total4y);
                        y5tot+=parseInt(element.total5y);
                    });
                    ftmp=[
                        {content: "Total", styles: { fontStyle: "bold"}},
                        {content: y0tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y1tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y2tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y3tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y4tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y5tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                    ];
                    body.push(ftmp);
                    //var tmp=[ "Total", y0tot, y1tot, y2tot, y3tot, y4tot, y5tot];
                    //body.push(tmp);
                    doc.autoTable(
                    {
                        head: head,
                        body: body,
                        theme:'grid',
                        startY: curOffset,
                        columnStyles: 
                        {
                            0: {halign: "left"},
                            1: {cellWidth: 25, halign: "center"},
                            2: {cellWidth: 25, halign: "center"},
                            3: {cellWidth: 25, halign: "center"},
                            4: {cellWidth: 25, halign: "center"},
                            5: {cellWidth: 25, halign: "center"},
                            6: {cellWidth: 25, halign: "center"}
                        },
                        headStyles: { halign:"center", fillColor:"#0397d5"},
                        
                        tableLineWidth: 0.3,
                        tableLineColor:0
                    });
                    curOffset=doc.autoTable.previous.finalY + 8;

                    head = 
                    [
                        [{"content":"Hours", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                        //[{"content":"Project Element", colspan:1},{"content":"Year 1", colspan:1}, {"content":"Year 2", colspan:1}, {"content":"Year 3", colspan:1}, {"content":"Year 4", colspan:1}, {"content":"Year 5", colspan:1}, {"content":"Year 6+", colspan:1}],
                        [{"content":"Project Element", colspan:1},{"content":res.startYear, colspan:1}, {"content":parseInt(res.startYear) + 1, colspan:1}, {"content":parseInt(res.startYear) + 2, colspan:1}, {"content":parseInt(res.startYear) + 3, colspan:1}, {"content":parseInt(res.startYear) + 4, colspan:1}, {"content": (parseInt(res.startYear)+5)+ "+", colspan:1}],
                    ];
                    body=[];
                    y0tot=0;
                    y1tot=0;
                    y2tot=0;
                    y3tot=0;
                    y4tot=0;
                    y5tot=0;
                    tots.elhours.forEach(element => {
                        var tmp=[ element.title,  parseInt(element.total0y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total1y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total2y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total3y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total4y).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.total5y).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                        body.push(tmp);
                        y0tot+=parseInt(element.total0y);
                        y1tot+=parseInt(element.total1y);
                        y2tot+=parseInt(element.total2y);
                        y3tot+=parseInt(element.total3y);
                        y4tot+=parseInt(element.total4y);
                        y5tot+=parseInt(element.total5y);
                    });
                    ftmp=[
                        {content: "Total", styles: { fontStyle: "bold"}},
                        {content: y0tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y1tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y2tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y3tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y4tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y5tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                    ];
                    body.push(ftmp);
                    doc.autoTable(
                    {
                        head: head,
                        body: body,
                            theme:'grid',
                            startY: curOffset,
                            columnStyles: 
                            {
                            0: {halign: "left"},
                            1: {cellWidth: 25, halign: "center"},
                            2: {cellWidth: 25, halign: "center"},
                            3: {cellWidth: 25, halign: "center"},
                            4: {cellWidth: 25, halign: "center"},
                            5: {cellWidth: 25, halign: "center"},
                            6: {cellWidth: 25, halign: "center"}
                            },
                            tableLineWidth: 0.3,
                            tableLineColor:0,
                            headStyles: { halign:"center", fillColor:"#0397d5"}
                    });
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    doc.text(new Date().toLocaleDateString('en-US'), 10, 207, 'left');
                    doc.text('Page 3/4', 269, 207, 'right');
                    doc.addPage('letter','l');
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text('MCWD CIP', 266, 20, 'right');
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                    var img = new Image();
                    img.src = 'assets/img/logo.png';
                    doc.addImage(img, 'PNG', 10, 7, 18, 16);
                    doc.setLineWidth(1.0); 
                    doc.line(10,25,266,25, 'DF');
                    
                    doc.autoTable(
                    {
                    body:
                        [
                        [
                            'Start Year', res.startYear
                        ]
                        ],
                        theme:'grid',
                        startY: 30,
                        columnStyles: 
                        {
                        0: {cellWidth: 20},
                        1: {cellWidth: 20}
                        },
                        tableLineWidth: 0.3,
                        tableLineColor:0,
                        tableWidth:'wrap'
                    });
                    curOffset=doc.autoTable.previous.finalY + 8;

                    
                    head = 
                    [
                        [{"content":"Costs, k$", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                        //[{"content":"Project Element", colspan:1},{"content":"Year 1", colspan:1}, {"content":"Year 2", colspan:1}, {"content":"Year 3", colspan:1}, {"content":"Year 4", colspan:1}, {"content":"Year 5", colspan:1}, {"content":"Year 6+", colspan:1}],
                        [{"content":"Project Element", colspan:1},{"content":res.startYear, colspan:1}, {"content":parseInt(res.startYear) + 1, colspan:1}, {"content":parseInt(res.startYear) + 2, colspan:1}, {"content":parseInt(res.startYear) + 3, colspan:1}, {"content":parseInt(res.startYear) + 4, colspan:1}, {"content": (parseInt(res.startYear)+5)+ "+", colspan:1}],
                    ];
                    body=[];
                    y0tot=0;
                    y1tot=0;
                    y2tot=0;
                    y3tot=0;
                    y4tot=0;
                    y5tot=0;
                    tots.costs.forEach(element => {
                        var tmp=[ element.title,  parseInt(element.y0c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y1c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y2c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y3c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y4c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y5pc).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                        body.push(tmp);
                        y0tot+=parseInt(element.y0c);
                        y1tot+=parseInt(element.y1c);
                        y2tot+=parseInt(element.y2c);
                        y3tot+=parseInt(element.y3c);
                        y4tot+=parseInt(element.y4c);
                        y5tot+=parseInt(element.y5pc);
                    });
                    ftmp=[
                        {content: "Total", styles: { fontStyle: "bold"}},
                        {content: y0tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y1tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y2tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y3tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y4tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                        {content: y5tot.toLocaleString('en-US', { minimumFractionDigits: 0 }), styles: { fontStyle: "bold"}},
                    ];
                    body.push(ftmp);
                    doc.autoTable(
                    {
                        head: head,
                        body: body,
                            theme:'grid',
                            startY: curOffset,
                            columnStyles: 
                            {
                            0: {halign: "left"},
                            1: {cellWidth: 25, halign: "center"},
                            2: {cellWidth: 25, halign: "center"},
                            3: {cellWidth: 25, halign: "center"},
                            4: {cellWidth: 25, halign: "center"},
                            5: {cellWidth: 25, halign: "center"},
                            6: {cellWidth: 25, halign: "center"}
                            },
                            tableLineWidth: 0.3,
                            tableLineColor:0,
                            headStyles: { halign:"center", fillColor:"#0397d5"}
                    });
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    doc.text(new Date().toLocaleDateString('en-US'), 10, 207, 'left');
                    doc.text('Page 4/4', 269, 207, 'right');
                    /*
                    doc.addPage('letter','p');
                    
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text('NID Hydro CIP', 206, 20, 'right');
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');

                    doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                    doc.setLineWidth(1.0); 
                    doc.line(10,25,206,25, 'DF');
                    curOffset=40;

                    doc.setFontStyle('bold');
                    doc.setFontSize(14);
                    doc.text("Critical Path to Start Project", 20, curOffset);
                    curOffset+= doc.getLineHeight("Critical Path to start Project") / doc.internal.scaleFactor
                    if(res.critical_paths.length >0 )
                    {
                        body=[]
                        res.critical_paths.forEach(element => {
                            var tmp=[ element.task, element.charge, this.format(element.start), this.format(element.complete)];
                            body.push(tmp);
                        });
                        
                        
                        doc.autoTable(
                        {
                            head:
                            [
                                [
                                'Task', 'Responsible Charge', 'Start', 'Complete'
                                ]
                            ],
                            body: body,
                            theme:'grid',
                            startY: curOffset,
                            columnStyles: 
                            {
                                0: {halign:"left"},
                                1: {cellWidth: 20, halign:"center"},
                                2: {cellWidth: 20, halign:"center"},
                                3: {cellWidth: 20, halign:"center"}
                            },
                            tableLineWidth: 0.3,
                            tableLineColor:0,
                            headStyles: { halign:"center", fillColor:"#0397d5"}
                        });
                        curOffset=doc.autoTable.previous.finalY + 8;
                    }
                    else
                    {
                        doc.autoTable(
                            {
                                head:
                                [
                                    [
                                    'Task', 'Responsible Charge', 'Start', 'Complete'
                                    ]
                                ],
                                body: [
                                    [
                                        {colSpan: 4, content: 'No data has been entered.'}
                                    ]
                                ],
                                theme:'grid',
                                startY: curOffset,
                                columnStyles: 
                                {
                                    0: {halign:"left"},
                                    1: {cellWidth: 20, halign:"center"},
                                    2: {cellWidth: 20, halign:"center"},
                                    3: {cellWidth: 20, halign:"center"}
                                },
                                tableLineWidth: 0.3,
                                tableLineColor:0,
                                headStyles: { halign:"center", fillColor:"#0397d5"}
                            });
                            curOffset=doc.autoTable.previous.finalY + 8;
                        /*doc.setFontStyle('normal');
                        doc.setFontSize(12);
                        doc.text("No Critical Path to Start Project has been entered.", 14, curOffset);
                        curOffset+= doc.getLineHeight("No Critical Path to Start Project has been entered.") / doc.internal.scaleFactor + 8
                        *
                    }
                    doc.setFontStyle('bold');
                    doc.setFontSize(14);
                    doc.text("Outage Considerations", 20, curOffset);
                    curOffset+= doc.getLineHeight("OutageConsiderations") / doc.internal.scaleFactor
                    if(res.outages.length>0)
                    {
                        body=[]
                        res.outages.forEach(element => {
                            var tmp=[ element.item, element.weeksPrior, element.length + " Weeks"];
                            body.push(tmp);
                        });
                        doc.autoTable(
                        {
                            head:
                            [
                                [
                                'Item', 'Weeks Prior to Outage', 'Required Outage Length'
                                ]
                            ],
                            body: body,
                            theme:'grid',
                            startY: curOffset,
                            columnStyles: 
                            {
                            0: {halign:"left"},
                            1: {halign:"center"},
                            2: {halign:"center"}
                            },
                            tableLineWidth: 0.3,
                            tableLineColor:0,
                            headStyles: { halign:"center", fillColor:"#0397d5"}
                        });
                        curOffset=doc.autoTable.previous.finalY + 8;
                    }
                    else
                    {
                        doc.autoTable(
                            {
                                head:
                                [
                                    [
                                    'Item', 'Weeks Prior to Outage', 'Required Outage Length'
                                    ]
                                ],
                                body: [
                                    [
                                        {colSpan: 4, content: 'No data has been entered.'}
                                    ]
                                ],
                                theme:'grid',
                                startY: curOffset,
                                columnStyles: 
                                {
                                0: {halign:"left"},
                                1: {halign:"center"},
                                2: {halign:"center"}
                                },
                                tableLineWidth: 0.3,
                                tableLineColor:0,
                                headStyles: { halign:"center", fillColor:"#0397d5"}
                            });
                            curOffset=doc.autoTable.previous.finalY + 8;

                        /*doc.setFontStyle('normal');
                        doc.setFontSize(12);
                        doc.text("No Outage Consideration has been entered.", 14, curOffset);
                        curOffset+= doc.getLineHeight("No Outage Consideration has been entered.") / doc.internal.scaleFactor + 8*
                    }
                    doc.setFontStyle('bold');
                    doc.setFontSize(14);
                    doc.text("Asset Lifecycle Cost", 20, curOffset);
                    curOffset+= doc.getLineHeight("Asset Lifecycle Cost") / doc.internal.scaleFactor
                    if(res.assets_costs.length>0)
                    {
                        body=[]
                        res.assets_costs.forEach(element => {
                            var tmp=[ element.category.description, element.category.definition, parseInt(element.hours).toLocaleString('en-US', { minimumFractionDigits: 0 }), parseInt(element.cost).toLocaleString('en-US', { minimumFractionDigits: 0 })];
                            body.push(tmp);
                        });
                        doc.autoTable(
                        {
                            head:
                            [
                                [
                                'Element', 'Period, yr', 'NID, hours', 'Cost,$k'
                                ]
                            ],
                            body: body,
                            theme:'grid',
                            startY: curOffset,
                            columnStyles: 
                            {
                            0: {cellWidth: 50, halign:"left"},
                            1: {cellWidth: 15, halign:"center"},
                            2: {cellWidth: 15, halign:"center"},
                            3: {cellWidth: 15, halign:"center"}
                            },
                            tableLineWidth: 0.3,
                            tableLineColor:0,
                            headStyles: { halign:"center", fillColor:"#0397d5"}
                        });
                        curOffset=doc.autoTable.previous.finalY + 8;
                    }
                    else
                    {
                        doc.autoTable(
                            {
                                head:
                                [
                                    [
                                    'Element', 'Period, yr', 'NID, hours', 'Cost,$k'
                                    ]
                                ],
                                body: [
                                    [
                                        {colSpan: 4, content: 'No data has been entered.'}
                                    ]
                                ],
                                theme:'grid',
                                startY: curOffset,
                                columnStyles: 
                                {
                                0: {cellWidth: 50, halign:"left"},
                                1: {cellWidth: 15, halign:"center"},
                                2: {cellWidth: 15, halign:"center"},
                                3: {cellWidth: 15, halign:"center"}
                                },
                                tableLineWidth: 0.3,
                                tableLineColor:0,
                                headStyles: { halign:"center", fillColor:"#0397d5"}
                            });
                            curOffset=doc.autoTable.previous.finalY + 8;
                        /*
                        doc.setFontStyle('normal');
                        doc.setFontSize(12);
                        doc.text("No Asset Lifecycle Cost has been entered.", 14, curOffset);
                        curOffset+= doc.getLineHeight("No Asset Lifecycle Cost has been entered.") / doc.internal.scaleFactor + 8
                        *
                    }*/
                doc.save('table.pdf');
            });
        });
    }
    public generateReportPage1(projectid)
    {
        this.rest.buildGetRequest('projects/' + projectid).subscribe((res : any)=>
        {
            this.rest.buildGetRequest('projecttotals/' + projectid).subscribe((tots : any)=>
            {
                /*var doc = new jsPDF('p', 'mm', 'letter');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                var img = new Image();
                img.src = 'assets/img/logo.png';
                doc.addImage(img, 'PNG', 10, 7, 18, 16);
                doc.setFontSize(14);
                doc.setFontStyle('bold');
                doc.text('MCWD CIP', 206, 20, 'right');
                doc.setLineWidth(1.0); 
                doc.line(10,25,206,25, 'DF');
                
                let thead = 
                    [
                        
                        [   
                            {"content":res.project_title, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_source, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ],
                        [   
                            {"content":res.CIP_0, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_trigger, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ]
                        //[res.title]
                    ];

                doc.autoTable(
                {
                    head: thead,
                    body:
                        [
                            
                            [
                                //res.facility.code, res.facility.name, res.budget_category.name, regulatoryString
                                '','mw','ms','ow','os','recycled','cm','cs','district-wide'
                            ],
                            [
                                '% Budget Split', res.marina_water_allocation, res.marina_sewer_allocation, res.ord_water_allocation, res.ord_sewer_allocation, res.recycled_allocation, res.combined_water_allocation, res.combined_sewer_allocation, res.district_wide_allocation,
                            ]
                        ],
                
                    theme:'grid',
                    startY: 30,
                    //headStyles: { halign:"center", fillColor: "#ffffff"},
                    
                    tableLineWidth: 1,
                    tableLineColor:0
                });
                doc.setFontSize(14);
                let curOffset= doc.autoTable.previous.finalY+15;

                if (res.project_description) {
                    doc.setFontStyle('bold');
                    doc.text("Project Description", 20, curOffset);
                    curOffset+= doc.getLineHeight("Project Description") / doc.internal.scaleFactor
                    
                    doc.setFontSize(12);
                    doc.setFontStyle('normal');
                    var splitTitle = doc.splitTextToSize(res.project_description, 180);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;
                }
                if (res.justification) {
                    doc.setFontSize(14);
                    doc.setFontStyle('bold');
                    doc.text("Justification", 20, curOffset);
                    curOffset+= doc.getLineHeight("Justification") / doc.internal.scaleFactor

                    doc.setFontSize(12);
                    doc.setFontStyle('normal');
                    splitTitle = doc.splitTextToSize(res.justification, 180);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;
                }
                if (res.path_cover!='') {
                    doc.setFontStyle('bold');
                    doc.text("Map Location", 20, curOffset);
                    curOffset+= doc.getLineHeight("Map Location") / doc.internal.scaleFactor
                    
                        var img = new Image();
                        img.src = this.API_ENDPOINT + "storage/" + res.path_cover;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                        doc.addImage(img, 'PNG', 37, curOffset, 152, 85);
                    
                    curOffset+=100;
                }
                doc.setFontStyle('bold');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                doc.text(new Date().toLocaleDateString('en-US'), 10, 270, 'left');
                //doc.text('Page 1/4', 205, 270, 'right');
                doc.save('table.pdf');*/
                var doc = new jsPDF('p', 'mm', 'letter');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                var img = new Image();
                img.src = 'assets/img/logo.png';
                //doc.addImage(img, 'PNG', 10, 7, 18, 16);
                //doc.setFontSize(14);
                //doc.setFontStyle('bold');
                //doc.text('MCWD CIP', 206, 20, 'right');
                //doc.setLineWidth(1.0); 
                doc.line(10,25,206,25, 'DF');
                doc.setFontSize(14);
                doc.setFontStyle('bold');
                //doc.text(res.project_title, 20, 17, 'left');
                var splitTitleProject = doc.splitTextToSize(res.project_title, 130);
                if (res.project_title.length > 96)
                    doc.text(20, 13, splitTitleProject);
                else doc.text(20, 15, splitTitleProject);
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                let start_year_f = this.formatDate(res.startYear);
                //doc.text('MCWD ' + start_year_f, 150, 14, 'left');
                //doc.text('Proposed CIP', 150, 19, 'left');
                doc.text('Project #:', 150, 15, 'left');
                doc.text(res.CIP_0, 167, 15, 'left');
                doc.addImage(img, 'PNG', 190, 6, 18, 16);
                doc.setLineWidth(1.0); 
                /*let thead = 
                    [
                        
                        [   
                            {"content":res.project_title, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_source, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ],
                        [   
                            {"content":res.CIP_0, colSpan:1, styles:{fillColor: "#ffffff",halign: "left", textColor:"#000000"}},
                            {"content":res.project_trigger, colSpan:8, styles:{fillColor: "#ffffff",halign: "right", textColor:"#000000"}}
                        ]
                        //[res.title]
                    ];

                doc.autoTable(
                {
                    head: thead,
                    body:
                        [
                            
                            [
                                //res.facility.code, res.facility.name, res.budget_category.name, regulatoryString
                                '','mw','ms','ow','os','recycled','cm','cs','district-wide'
                            ],
                            [
                                '% Budget Split', res.marina_water_allocation, res.marina_sewer_allocation, res.ord_water_allocation, res.ord_sewer_allocation, res.recycled_allocation, res.combined_water_allocation, res.combined_sewer_allocation, res.district_wide_allocation,
                            ]
                        ],
                
                    theme:'grid',
                    startY: 10,
                    //headStyles: { halign:"center", fillColor: "#ffffff"},
                    
                    tableLineWidth: 1,
                    tableLineColor:0
                });
                doc.setFontSize(14);
                let curOffset= doc.autoTable.previous.finalY+15;*/
                let curOffset=35;
                var curOffset1=35;
                var splitTitle;
                if (res.project_description) {
                    doc.setFontSize(13);
                    doc.setFontStyle('bold');
                    doc.text("Project Description", 20, curOffset);
                    curOffset+= doc.getLineHeight("Project Description") / doc.internal.scaleFactor
                    
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    splitTitle = doc.splitTextToSize(res.project_description, 80);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 6;
                }

                doc.setFontSize(13);
                doc.setFontStyle('bold');
                doc.text("Project Need", 118, 35);
                if (res.justification) {
                    curOffset1+= doc.getLineHeight("Project Need") / doc.internal.scaleFactor
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    var splitTitle1 = doc.splitTextToSize(res.justification, 80);
                    doc.text(118, curOffset1, splitTitle1);
                }
                doc.setFontSize(13);
                doc.setFontStyle('bold');
                doc.text("Project map", 20, curOffset);
                if (res.path_cover!='') {
                    curOffset+= doc.getLineHeight("Project map") / doc.internal.scaleFactor
                    var img = new Image();
                    img.src = this.API_ENDPOINT + "storage/" + res.path_cover;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                    doc.addImage(img, 'PNG', 55, curOffset, 110, 70);
                } else curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;

                curOffset=170;
                let head = 
                [
                    //[{"content":"Costs, k$", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                    //[{"content":"Project Element", colspan:1},{"content":"Year 1", colspan:1}, {"content":"Year 2", colspan:1}, {"content":"Year 3", colspan:1}, {"content":"Year 4", colspan:1}, {"content":"Year 5", colspan:1}, {"content":"Year 6+", colspan:1}],
                    [
                        {"content":"Project Budget, $k", colspan:1}, 
                        {"content":this.formatDate(res.startYear) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 1) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 2) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 3) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 4) + "\n $k", colspan:1}, 
                        {"content": 'Out Years \n $k', colspan:1}
                        //{"content": this.formatDate((parseInt(res.startYear)+5))+ "+", colspan:1}
                    ]
                ];
                let body=[];
                let y0tot=0;
                let y1tot=0;
                let y2tot=0;
                let y3tot=0;
                let y4tot=0;
                let y5tot=0;
                let total=0;
                tots.costs.forEach(element => {
                    var tmp=[ element.title,  parseInt(element.y0c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y1c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y2c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y3c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y4c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y5pc).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                    //body.push(tmp);
                    y0tot+=parseInt(element.y0c);
                    y1tot+=parseInt(element.y1c);
                    y2tot+=parseInt(element.y2c);
                    y3tot+=parseInt(element.y3c);
                    y4tot+=parseInt(element.y4c);
                    y5tot+=parseInt(element.y5pc);
                });
                total=(y0tot+y1tot+y2tot+y3tot+y4tot+y5tot)/1000;
                let ftmp=[
                    //{content: "Total", styles: { fontStyle: "bold"}},
                    {content: (total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y0tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y1tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y2tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y3tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y4tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y5tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                ];
                body.push(ftmp);
                doc.autoTable(
                {
                    head: head,
                    body: body,
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#0397d5", lineWidth: 0.3}
                });
                curOffset=doc.autoTable.previous.finalY + 8;

                // Tabella water systems
                let thead = 
                [
                    [
                        {"content":"Cost \nAllocation", colspan:1},
                        {"content":"Marina Water", colspan:1},
                        {"content":"Marina Sewer", colspan:1},
                        {"content":"Ord Water", colspan:1},
                        {"content":"Ord Sewer", colspan:1},
                        {"content":"Recycled", colspan:1},
                        {"content":"GSA", colspan:1},
                    ]
                ];

                let tbody = [
                            
                    [
                        'Percent', 
                        res.marina_water_allocation, 
                        res.marina_sewer_allocation, 
                        res.ord_water_allocation, 
                        res.ord_sewer_allocation, 
                        res.recycled_allocation, 
                        res.gsa_allocation
                    ],
                    [
                        '$k', 
                        (res.marina_water_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.marina_sewer_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.ord_water_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.ord_sewer_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.recycled_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.gsa_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    ]
                ];

                doc.autoTable(
                {
                    head: thead,
                    body: tbody,                
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#0397d5", lineWidth: 0.3}
                });
                doc.setFontSize(14);
                curOffset= doc.autoTable.previous.finalY+8;

                // Tabella fund systems
                let fhead = 
                [
                    [
                        {"content":"Funding \nSource", colspan:1},
                        {"content":"Bond", colspan:1},
                        {"content":"Capacity Fee", colspan:1},
                        {"content":"Capital \n Replacement", colspan:1},
                        {"content":"GSA", colspan:1},
                        {"content":"Grants", colspan:1},
                        {"content":"Other", colspan:1}
                    ]
                ];

                let fbody = 
                [
                    [
                        'Percent', 
                        res.bonds_fund_allocation, 
                        res.capacity_fee_fund_allocation, 
                        res.capital_reserves_fund_allocation, 
                        res.gsa_fund_allocation, 
                        res.grant_fund_allocation, 
                        res.other_fund_allocation
                    ],
                    [
                        '$k', 
                        (res.bonds_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.capacity_fee_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.capital_reserves_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.gsa_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.grant_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.other_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    ]
                ];

                doc.autoTable(
                {
                    head: fhead,
                    body: fbody,                
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#0397d5", lineWidth: 0.3}
                });
                doc.setFontSize(14);
                curOffset= doc.autoTable.previous.finalY+8;
                doc.save('table.pdf');
            });
        });
    }
    public generateReportPage1Edited(projectid)
    {
        this.rest.buildGetRequest('projects/' + projectid).subscribe((res : any)=>
        {
            this.rest.buildGetRequest('projecttotals1page/' + projectid).subscribe((tots : any)=>
            {
                console.log(res);
                var doc = new jsPDF('p', 'mm', 'letter');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                //doc.text(new Date().toLocaleDateString('en-US'), 10, 20, 'left');
                var img = new Image();
                img.src = 'assets/img/logo.png';
                //doc.addImage(img, 'PNG', 10, 7, 18, 16);
                //doc.setFontSize(14);
                //doc.setFontStyle('bold');
                //doc.text('MCWD CIP', 206, 20, 'right');
                //doc.setLineWidth(1.0); 
                doc.line(10,25,206,25, 'DF');
                doc.setFontSize(14);
                doc.setFontStyle('bold');
                //doc.text(res.project_title, 20, 17, 'left');
                var splitTitleProject = doc.splitTextToSize(res.project_title, 130);
                if (res.project_title.length > 96)
                    doc.text(20, 13, splitTitleProject);
                else doc.text(20, 15, splitTitleProject);
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                let start_year_f = this.formatDate(res.startYear);
                //doc.text('MCWD ' + start_year_f, 150, 14, 'left');
                //doc.text('Proposed CIP', 150, 19, 'left');
                doc.text('Project #:', 150, 15, 'left');
                if (res.CIP_0)
                doc.text(res.CIP_0, 167, 15, 'left');
                doc.addImage(img, 'PNG', 190, 6, 18, 16);
                doc.setLineWidth(1.0); 

                let curOffset=35;
                var curOffset1=35;
                var splitTitle;
                if (res.project_description) {
                    doc.setFontSize(13);
                    doc.setFontStyle('bold');
                    doc.text("Project Description", 20, curOffset);
                    curOffset+= doc.getLineHeight("Project Description") / doc.internal.scaleFactor
                    
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    splitTitle = doc.splitTextToSize(res.project_description, 80);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 6;
                }

                doc.setFontSize(13);
                doc.setFontStyle('bold');
                doc.text("Project Need", 118, 35);
                if (res.justification) {
                    curOffset1+= doc.getLineHeight("Project Need") / doc.internal.scaleFactor
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    var splitTitle1 = doc.splitTextToSize(res.justification, 80);
                    doc.text(118, curOffset1, splitTitle1);
                    curOffset1+= (doc.getLineHeight(splitTitle1) / doc.internal.scaleFactor) * splitTitle1.length + 6;
                }
                curOffset = Math.max(curOffset, curOffset1);
                console.log(curOffset);
                let diff = curOffset > 85 ? (curOffset - 85) : 0;
                console.log(diff);
                //doc.text("Project map", 20, curOffset);
                if (res.path_cover!='') {
                    curOffset+= doc.getLineHeight("Project map") / doc.internal.scaleFactor
                    var img = new Image();
                    img.src = this.API_ENDPOINT + "storage/" + res.path_cover;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                    doc.addImage(img, 'PNG', 20, curOffset, 175, 140 - diff);
                } else curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 8;

                curOffset=235;
                doc.setFontSize(13);
                doc.setFontStyle('bold');
                doc.text("Projected 5-Year CIP Budget", 79, curOffset);
                curOffset+= doc.getLineHeight("Projected 5-Year CIP Budget") / doc.internal.scaleFactor

                let currYear = new Date().getFullYear();
                let head = 
                [
                    //[{"content":"Costs, k$", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                    //[{"content":"Project Element", colspan:1},{"content":"Year 1", colspan:1}, {"content":"Year 2", colspan:1}, {"content":"Year 3", colspan:1}, {"content":"Year 4", colspan:1}, {"content":"Year 5", colspan:1}, {"content":"Year 6+", colspan:1}],
                    [
                        {"content":"Total Projected Budget, $k", colspan:1}, 
                        {"content":this.formatDate(/*res.startYear*/currYear) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(currYear + 1) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(currYear + 2) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(currYear + 3) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(currYear + 4) + "\n $k", colspan:1}, 
                        {"content": 'Out Years \n $k', colspan:1}
                        //{"content": this.formatDate((parseInt(res.startYear)+5))+ "+", colspan:1}
                    ]
                ];
                let body=[];
                let y0tot=0;
                let y1tot=0;
                let y2tot=0;
                let y3tot=0;
                let y4tot=0;
                let y5tot=0;
                let total=0;
                tots.costs.forEach(element => {
                    var tmp=[ element.title,  parseInt(element.y0c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y1c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y2c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y3c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y4c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y5pc).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                    //body.push(tmp);
                    y0tot+=parseInt(element.y0c);
                    y1tot+=parseInt(element.y1c);
                    y2tot+=parseInt(element.y2c);
                    y3tot+=parseInt(element.y3c);
                    y4tot+=parseInt(element.y4c);
                    y5tot+=parseInt(element.y5pc);
                });
                total=(y0tot+y1tot+y2tot+y3tot+y4tot+y5tot)/1000;
                let ftmp=[
                    //{content: "Total", styles: { fontStyle: "bold"}},
                    {content: (total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y0tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y1tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y2tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y3tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y4tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y5tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                ];
                body.push(ftmp);
                doc.autoTable(
                {
                    head: head,
                    body: body,
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#29205c", lineWidth: 0.3}
                });
                curOffset=doc.autoTable.previous.finalY + 8;
                doc.save('table.pdf');
            });
        });
    }
    public generateReportPage2(projectid)
    {
        this.rest.buildGetRequest('projects/' + projectid).subscribe((res : any)=>
        {
            this.rest.buildGetRequest('projecttotals/' + projectid).subscribe((tots : any)=>
            {
                var doc = new jsPDF('l', 'mm', 'letter');
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                var img = new Image();
                img.src = 'assets/img/logo.png';
                doc.line(10,25,270,25, 'DF');
                doc.setFontSize(15);
                doc.setFontStyle('bold');
                //doc.text(res.project_title, 20, 17, 'left');
                var splitTitleProject = doc.splitTextToSize(res.project_title, 180);
                if (res.project_title.length > 96)
                    doc.text(20, 13, splitTitleProject);
                else doc.text(20, 15, splitTitleProject);
                doc.setFontSize(15);
                doc.setFontStyle('bold');
                doc.text('Project #:', 180, 15, 'left');
                doc.text(res.CIP_0, 207, 15, 'left');
                doc.addImage(img, 'PNG', 240, 6, 18, 16);
                
                let curOffset=35;
                var splitTitle;
                if (res.project_description) {
                    doc.setFontSize(12);
                    doc.setFontStyle('bold');
                    doc.text("Project Description", 20, curOffset);
                    curOffset+= doc.getLineHeight("Project Description") / doc.internal.scaleFactor
                    
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    splitTitle = doc.splitTextToSize(res.project_description, 240);
                    doc.text(20, curOffset, splitTitle);
                    curOffset+= (doc.getLineHeight(splitTitle) / doc.internal.scaleFactor) * splitTitle.length + 6;
                }

                curOffset=73;
                doc.setFontSize(12);
                doc.setFontStyle('bold');
                doc.text("Project Need", 20, curOffset);
                curOffset+= doc.getLineHeight("Project Need") / doc.internal.scaleFactor
                if (res.justification) {
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    var splitTitle1 = doc.splitTextToSize(res.justification, 240);
                    doc.text(20, curOffset, splitTitle1);
                    curOffset+= (doc.getLineHeight(splitTitle1) / doc.internal.scaleFactor) * splitTitle1.length + 6;
                }

                curOffset=110;
                doc.setFontSize(12);
                doc.setFontStyle('bold');
                doc.text("Environmental and Permitting Requirements.", 20, curOffset);
                curOffset+= doc.getLineHeight("Environmental and Permitting Requirements.") / doc.internal.scaleFactor
                if (res.environment_requirement) {
                    doc.setFontSize(10);
                    doc.setFontStyle('normal');
                    var splitTitle1 = doc.splitTextToSize(res.environment_requirement, 240);
                    doc.text(20, curOffset, splitTitle1);
                    curOffset+= (doc.getLineHeight(splitTitle1) / doc.internal.scaleFactor) * splitTitle1.length;
                }

                curOffset = 140;

                let head = 
                [
                    //[{"content":"Costs, k$", colSpan:7, halign: "center", fillColor: [22, 160, 133]},],
                    //[{"content":"Project Element", colspan:1},{"content":"Year 1", colspan:1}, {"content":"Year 2", colspan:1}, {"content":"Year 3", colspan:1}, {"content":"Year 4", colspan:1}, {"content":"Year 5", colspan:1}, {"content":"Year 6+", colspan:1}],
                    [
                        {"content":"Project Budget, $k", colspan:1}, 
                        {"content":this.formatDate(res.startYear) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 1) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 2) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 3) + "\n $k", colspan:1}, 
                        {"content":this.formatDate(parseInt(res.startYear) + 4) + "\n $k", colspan:1}, 
                        {"content": 'Out Years \n $k', colspan:1}
                        //{"content": this.formatDate((parseInt(res.startYear)+5))+ "+", colspan:1}
                    ]
                ];
                let body=[];
                let y0tot=0;
                let y1tot=0;
                let y2tot=0;
                let y3tot=0;
                let y4tot=0;
                let y5tot=0;
                let total=0;
                tots.costs.forEach(element => {
                    var tmp=[ element.title,  parseInt(element.y0c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y1c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y2c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y3c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y4c).toLocaleString('en-US', { minimumFractionDigits: 0 }),  parseInt(element.y5pc).toLocaleString('en-US', { minimumFractionDigits: 0 }) ];
                    //body.push(tmp);
                    y0tot+=parseInt(element.y0c);
                    y1tot+=parseInt(element.y1c);
                    y2tot+=parseInt(element.y2c);
                    y3tot+=parseInt(element.y3c);
                    y4tot+=parseInt(element.y4c);
                    y5tot+=parseInt(element.y5pc);
                });
                total=(y0tot+y1tot+y2tot+y3tot+y4tot+y5tot)/1000;
                let ftmp=[
                    //{content: "Total", styles: { fontStyle: "bold"}},
                    {content: (total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y0tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y1tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y2tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y3tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y4tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                    {content: (y5tot/1000).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), styles: { fontStyle: "normal"}},
                ];
                body.push(ftmp);
                doc.autoTable(
                {
                    head: head,
                    body: body,
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableWidth: 240,
                    margin: { left: 20 },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#0397d5", lineWidth: 0.3}
                });
                curOffset=doc.autoTable.previous.finalY + 8;

                // Tabella fund systems
                let fhead = 
                [
                    [
                        {"content":"Funding \nSource", colspan:1},
                        {"content":"Bond", colspan:1},
                        {"content":"Capacity Fee", colspan:1},
                        {"content":"Capital \n Replacement", colspan:1},
                        {"content":"GSA", colspan:1},
                        {"content":"Grants", colspan:1},
                        {"content":"Other", colspan:1}
                    ]
                ];

                let fbody = 
                [
                    [
                        'Percent', 
                        res.bonds_fund_allocation, 
                        res.capacity_fee_fund_allocation, 
                        res.capital_reserves_fund_allocation, 
                        res.gsa_fund_allocation, 
                        res.grant_fund_allocation, 
                        res.other_fund_allocation
                    ],
                    [
                        '$k', 
                        (res.bonds_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.capacity_fee_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.capital_reserves_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.gsa_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.grant_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), 
                        (res.other_fund_allocation / 100 * total).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    ]
                ];

                doc.autoTable(
                {
                    head: fhead,
                    body: fbody,                
                    theme:'grid',
                    startY: curOffset,
                    columnStyles: 
                        {
                        0: {cellWidth: 25, halign: "center"},
                        1: {cellWidth: 25, halign: "center"},
                        2: {cellWidth: 25, halign: "center"},
                        3: {cellWidth: 25, halign: "center"},
                        4: {cellWidth: 25, halign: "center"},
                        5: {cellWidth: 25, halign: "center"},
                        6: {cellWidth: 25, halign: "center"}
                        },
                    tableWidth: 240,
                    margin: { left: 20 },
                    tableLineWidth: 0.3,
                    tableLineColor:0,
                    headStyles: { cellWidth: 25, halign:"center", fillColor:"#0397d5", lineWidth: 0.3}
                });

                doc.addPage();
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                doc.line(10,25,270,25, 'DF');
                doc.setFontSize(14);
                doc.setFontStyle('bold');
                //doc.text(res.project_title, 20, 17, 'left');
                var splitTitleProject = doc.splitTextToSize(res.project_title, 130);
                if (res.project_title.length > 96)
                    doc.text(20, 13, splitTitleProject);
                else doc.text(20, 15, splitTitleProject);
                doc.setFontSize(10);
                doc.setFontStyle('normal');
                doc.text('Project #:', 200, 15, 'left');
                doc.text(res.CIP_0, 217, 15, 'left');
                doc.addImage(img, 'PNG', 240, 6, 18, 16);
                
                curOffset=35;
                doc.setFontSize(12);
                doc.setFontStyle('bold');
                doc.text("Project Map", 20, curOffset);
                curOffset+= doc.getLineHeight("Project Map") / doc.internal.scaleFactor
                if (res.path_cover!='') {
                    var img = new Image();
                    img.src = this.API_ENDPOINT + "storage/" + res.path_cover;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                    doc.addImage(img, res.path_cover.split('.').pop().toUpperCase(), 20, curOffset, 150, 100);
                };

                curOffset+=90;
                doc.setFontSize(12);
                doc.setFontStyle('bold');
                doc.text("Vicinity Map", 180, curOffset);
                curOffset+= doc.getLineHeight("Vicinity Map") / doc.internal.scaleFactor
                if (res.path_vicinity!='') {
                    var img = new Image();
                    img.src = this.API_ENDPOINT + "storage/" + res.path_vicinity;//this.GMapCircle(res.latitude, res.longitude, res.radius, res.mapZoom);
                    console.log(res.path_vicinity.split('.').pop());
                    doc.addImage(img, res.path_vicinity.split('.').pop().toUpperCase(), 180, curOffset, 90, 70);
                }
                doc.save('table.pdf');
            });
        });
    }
    GMapCircle(lat,lng,rad,zoom, detail=8) {
        var uri = 'https://maps.googleapis.com/maps/api/staticmap?';
        var staticMapSrc = 'center=' + lat + ',' + lng;
        staticMapSrc += '&size=711x400';
        staticMapSrc += '&key=' + this.config.getConfig("GMAPS_API_KEY");
        staticMapSrc += '&zoom='+zoom;
        staticMapSrc += '&path=color:0xff0000ff:weight:1';
        
        
        var r    = 6371;
        
        var pi   = Math.PI;
        
        var _lat  = (lat * pi) / 180;
        var _lng  = (lng * pi) / 180;
        var d    = (rad/1000) / r;
        
        var i = 0;
        
        for(i = 0; i <= 360; i+=detail) {
            var brng = i * pi / 180;
        
            var pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng));
            var pLng = ((_lng + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) * 180) / pi;
            pLat = (pLat * 180) / pi;
        
           staticMapSrc += "|" + pLat + "," + pLng;
        }
        
        return( uri + encodeURI(staticMapSrc));
    }
    formatDate(date: number) {
        console.log(date);
        if (date) {
            let year = date.toString().substring(2,4);
            return 'FY' + year + '/' + (date+1).toString().substring(2,4)
        } else return ''
    }
}