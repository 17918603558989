import {Component, OnInit, Inject, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RestService } from '../services';


@Component({
  selector: 'app-my-profile-dialog',
  templateUrl: './my-profile-dialog.component.html',
  styleUrls: ['./my-profile-dialog.component.scss']
})
export class MyProfileDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MyProfileDialogComponent>, public rest: RestService){}
  password: string="";
  verifyPassword: string="";
  get userData(): any {
    return JSON.parse(localStorage.getItem('userData'));
  }
  ngOnInit() {
  }
  save()
  {
    var updUser:any={ password: this.password, name:this.userData.name, email:this.userData.email, dept_id:this.userData.dept_id, level: this.userData.level };
    this.rest.buildPutRequest('edituser/' + this.userData.id, updUser).subscribe((res : any[])=>{
      this.dialogRef.close();
    });
  }
  close()
  {
    this.dialogRef.close();
  }
  get passwordError(): Boolean
  {
    
    if(this.password=="" && this.verifyPassword=="")
    {
      return false;
    }
    else if(this.password== this.verifyPassword)
    {
      return false;
    }
    else
    {
      return true;
    }
  }
}
