import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private styleTag:any;
  private _loading: boolean = false;
  loadingStatus: Subject<any> = new Subject();
  

  get loading():boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.styleTag = this.buildStyleElement();
    window.scroll(0,0);
    document.body.appendChild( this.styleTag );
    this.loading = true;
  }

  stopLoading() {
    document.body.removeChild( this.styleTag );
    this.loading = false;
  }

  private buildStyleElement() : HTMLStyleElement {

    var style = document.createElement( "style" );

    style.type = "text/css";
    style.setAttribute( "data-debug", "Injected by WindowScrolling service." );
    style.textContent = `
        body {
            overflow: hidden !important ;
        }
    `;

    return( style );

}
}