import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { User } from '../models';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private config: AppConfig) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    
    login(email: string, password: string) {
        return this.http.post<any>( this.config.getConfig("API_ENDPOINT") + 'login', { email, password }, 
            {
                headers: {'Accept':'application/json'}
            }
        )
            .pipe(map(resp => {
                // login successful if there's a jwt token in the response
                
                if (resp && resp.token) {
                    
                    localStorage.setItem('currentUser', JSON.stringify(resp));
                    localStorage.setItem('userData', JSON.stringify(resp.user));
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    
                    
                }
                this.currentUserSubject.next(resp);
                
            }));
            
    }

    logout() {
        // remove user from local storage to log user out
        
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userData');
        this.currentUserSubject.next(null);
    }
}