import {Injectable} from "@angular/core";
import {LazyMapsAPILoaderConfigLiteral} from "@agm/core";
import {AppConfig} from '../../config/app.config';

@Injectable()
export class MapsConfig implements LazyMapsAPILoaderConfigLiteral {
  public apiKey: string
  public libraries: string[]
  constructor(config: AppConfig) {

    this.apiKey = config.getConfig("GMAPS_API_KEY");
    this.libraries = ['places'];
    //console.log("lazy map init with " + this.apiKey)
  }
}