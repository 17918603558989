import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSort } from '@angular/material';
import { MatDialogModule} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { MatTableModule } from '@angular/material';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MyProfileDialogComponent } from 'app/my-profile-dialog/my-profile-dialog.component';
import { APP_INITIALIZER } from '@angular/core';
import {AppConfig} from '../config/app.config';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { LAZY_MAPS_API_CONFIG } from '@agm/core/services';
import { MapsConfig } from './services/mapsConfig.service';
import { JwtInterceptor, ErrorInterceptor } from './helpers';

import { AppComponent } from './app.component';
import { LoginComponent } from './login'

import { ProjectdetailsComponent } from './projectdetails/projectdetails.component';
import { DashboardComponent } from './dashboard/dashboard.component';


import { ProjectsComponent } from './projects/projects.component';
import { TableListComponent } from './table-list/table-list.component';


import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MatButtonModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,} from '@angular/material';
import { SummaryComponent } from './summary/summary.component';
import { PDFGeneratorService } from './services';
import { ExcelService } from './services';

import { LoadingScreenInterceptor } from "./helpers/loading.interceptor";
import { ReportsComponent } from './reports/reports.component';
//import { MyProfileDialogComponent } from './my-profile-dialog/my-profile-dialog.component';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    BrowserModule,
    ComponentsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTableModule,
    CdkTableModule,
    MatDialogModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    MyProfileDialogComponent,
    
  ],
  entryComponents: [MyProfileDialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
    
    AppConfig,
    { 
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true 
    },
    PDFGeneratorService,
    ExcelService,
    
    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
