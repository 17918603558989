import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';
import { PARAMETERS } from '@angular/core/src/util/decorators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //console.log("request intercepted " + request.method + " " + request.url);
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            var newurl  = request.url;
            let params= request.params;
            if(request.method == 'GET' || request.method == 'PUT' || request.method=='DELETE' || request.url.includes("image") || request.url.includes("vicinity"))
            {
                //console.log("appending token to PUT, DELETE or GET request");
                params= params.append('token', currentUser.token);
                

              //var newparams= request.params.append('token',currentUser.token);
              
            }
            else if (request.method=='POST')
            {
                let curr= request.body;
                //console.log(request.body);
                
                request = request.clone(
                    {
                        body:{...request.body, token:currentUser.token}
                    }
                );
                //request.body=
            }
            //console.log("params: " + params);
            //console.log("Setting headers");
            request = request.clone({
               params:params,
                setHeaders: {
                    accept : 'application/json',
                    //Authorization: `Bearer ${currentUser.token}`
                }
                
            });
            //console.log(request.headers);
        }
        
        return next.handle(request);
    }
}