import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { MyProfileDialogComponent } from 'app/my-profile-dialog/my-profile-dialog.component';

import { AuthenticationService } from '../../services';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    maxLevel: number;
    includeInMenu: boolean;
}
export const ROUTES: RouteInfo[] = [
    
    { path: '/summary', title: 'CIP Summary',  icon:'content_paste', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/cip-list', title: '5 Year CIP Project list ',  icon:'assignment', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/projects', title: 'Projects',  icon:'library_books', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/reports', title: 'Query & Reports',  icon:'find_in_page', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/table-list', title: 'Users Management',  icon:'supervised_user_circle', class: '', maxLevel: 1, includeInMenu: true },
    { path: '/project-details', title: 'Project Details',  icon:'supervised_user_circle', class: '', maxLevel: 2, includeInMenu: false },
    //{ path: '/project-dependencies', title: 'Project Dependencies',  icon:'shuffle', class: '', maxLevel: 2, includeInMenu: true },
    { path: '/dependencies-details', title: 'Project Dependencies Details',  icon:'shuffle', class: '', maxLevel: 2, includeInMenu: false },
    //{ path: '/logout', title: 'Logout',  icon:'exit_to_app', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  get username(): any {
    return JSON.parse(localStorage.getItem('userData')).name;
  
  }
  constructor( private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog, ) { }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ngOnInit() {
    let newinfo:RouteInfo[]=[];
    if(JSON.parse(localStorage.getItem("userData"))== null)
    {
      console.log("user doesn't exist");
      
    }
    var userLevel=JSON.parse(localStorage.getItem("userData")).level;
    //console.log("setting routes");
    ROUTES.forEach(element => {
      if(element.includeInMenu)
      {
        if(element.maxLevel >= userLevel)
          newinfo.push(element);
      }
    });
    this.menuItems = newinfo.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
  openUserProfileDialog()
    {
        this.dialog.open(MyProfileDialogComponent);
        /*const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              message: "Are you sure that you want to delete this user?",
              YesNo: true
            }
          });*/
    }
}
